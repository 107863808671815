import React from "react";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";

// component
import FooterMenu from "./FooterMenu";


/**
 * Footer component
 * @param siteTitle {string} - サイトのタイトル
 * @returns React Element {*}
 */
interface IFooterDefaultProps {
  siteTitle?: string,
}

const Footer = (props: IFooterDefaultProps) => {
  const query = useStaticQuery(graphql`
      query {
          securityActionImage: file(relativePath: {eq: "security_action_futatsuboshi-small_color.png"}) {
              childImageSharp {
                  fixed(width: 179, height: 179) {
                      ...GatsbyImageSharpFixed
                  }
              }
          },
      }
  `);

  return (
    <footer>
      <div className="footer">
        <div className="footer__inner">
          <div className="footer-copyright">© 2006 - {new Date().getFullYear()}, {props.siteTitle}</div>
          <FooterMenu />
          <div className="grid-container">
            <div className="grid-x align-center-middle">
              <div className="cell">
                <Img
                  fixed={query.securityActionImage.childImageSharp.fixed}
                  style={{ display: "block", margin: "0 0 0 auto" }}
                  alt="SECURITY ACTION ★★ セキュリティ対策自己宣言"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultTypes = {
  siteTitle: ""
};

export default Footer;