import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"


// Components
import Transition from "../Transition"
import Header from "../HeaderV2"
import Footer from "../Footer"
import Typekit from "../Typekit"
import FontAwesome from "../FontAwesome"


// Font Awesome
import { library } from "@fortawesome/fontawesome-svg-core"

// コンポーネントなどで使用するアイコンを読み込む。ただしスタイルシートで使用するものは含めない。
import {
    faCheckCircle as fasCheckCircle,
    faExclamationCircle as fasExclamationCircle,
    faDotCircle as fasDotCircle,
} from "@fortawesome/pro-solid-svg-icons"

import {
    faPlusCircle as farPlusCircle,
    faUndoAlt as farUndoAlt,
} from "@fortawesome/pro-regular-svg-icons"

import {
    faCheck as falCheck,
    faAlignJustify as falAlignJustify,
    faTimes as falTimes,
    faTimesCircle as falTimesCircle,
    faHandPaper as falHandPaper,
    faSadTear as falSadTear,
    faCity as falCity,
    faBus as falBus,
    faCar as falCar,
    faArrowCircleRight as falArrowCircleRight,
    faArrowCircleLeft as falArrowCircleLeft,
    faChevronRight as falChevronRight,
    faHomeAlt as falHomeAlt,
    faSitemap as falSitemap,
    faChevronUp as falChevronUp,
    faHistory as falHistory,
    faEnvelope as falEnvelope,
} from "@fortawesome/pro-light-svg-icons"

import {
    faAsterisk as fabAsterisk,
} from "@fortawesome/pro-duotone-svg-icons"

// 読み込んだアイコンを追加する
library.add(
    fasCheckCircle,
    fasExclamationCircle,
    fasDotCircle,
)
library.add(
    farPlusCircle,
    farUndoAlt,
)
library.add(
    falCheck,
    falAlignJustify,
    falTimes,
    falTimesCircle,
    falHandPaper,
    falSadTear,
    falCity,
    falBus,
    falCar,
    falArrowCircleRight,
    falArrowCircleLeft,
    falChevronRight,
    falHomeAlt,
    falSitemap,
    falChevronUp,
    falHistory,
    falEnvelope,
)
library.add(fabAsterisk)


// Style
import "../../styles/main.scss"


/**
 * Layout base component
 *
 * @param {string} pageId -
 * @param {string} pageTitle? -
 * @param {object[]} crumbs -
 * @param {any} location -
 * @param {boolean} headerSmallOfPageTop? - スクロール量が 0 (一番上)のときヘッダーを小さくする (default: true)
 * @param {boolean} headerSmallOfSticky? - スクロール量が 0 以上のときヘッダーを小さくする (default: true)
 * @param {boolean} headerCoverUp? - スクロール量が 0 (一番上) のときヘッダーを下の要素の上に被せる (default: false)
 * @param {React.ReactNode} children - 子要素
 * @return {*} React element
 */
interface ILayoutBaseProps {
    pageId: string,
    pageTitle?: string,
    crumbs: object[],
    location: any,
    headerSmallOfPageTop?: boolean,
    headerSmallOfSticky?: boolean,
    headerCoverUp?: boolean,
    children: React.ReactNode,
}

const LayoutBase = (props: ILayoutBaseProps) => {
    const { site } = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <Fragment>
            <Transition location={props.location}>
                <Header
                    pageId={props.pageId}
                    crumbs={props.crumbs}
                    siteTitle={site.siteMetadata.title}
                    headerSmallOfPageTop={props.headerSmallOfPageTop}
                    headerSmallOfSticky={props.headerSmallOfSticky}
                    coverUp={props.headerCoverUp}
                />
                <main>
                    {props.children}
                </main>
                <Footer siteTitle={site.siteMetadata.title}/>
            </Transition>
            <Typekit/>
            <FontAwesome/>
        </Fragment>
    )
}
LayoutBase.defaultProps = {
    pageTitle: ``,
    headerSmallOfPageTop: true,
    headerSmallOfSticky: true,
    headerCoverUp: false,
}

export default LayoutBase

