import React, { Fragment } from "react"
import {
    TransitionGroup,
    Transition as ReactTransition,
} from "react-transition-group"


const timeout = 1000
const getTransitionStyles: any = {
    entering: {
        position: `absolute`,
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 0,
    },
}


type TProps = {
    children: any,
    location: any,
}

class Transition extends React.PureComponent<TProps> {
    render() {
        const { children, location } = this.props


        return (
            <TransitionGroup
                appear={true}
                enter={true}
                exit={true}
            >
                <ReactTransition
                    key={location.pathname}
                    timeout={{
                        appear: 0,
                        enter: timeout,
                        exit: timeout,
                    }}
                >
                    {status => (
                        <div
                            style={{
                                ...getTransitionStyles[status],
                            }}
                        >
                            {children}
                        </div>
                    )}
                </ReactTransition>
            </TransitionGroup>
        )
    }
}


export default Transition